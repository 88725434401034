import { Injectable } from '@angular/core';
import { LoggerService } from '@core/services/logger/logger.service';
import { TeethDiagramStore } from '@modules/teeth-diagram/state/teeth-diagram-store';
import { PopUpActions } from '@shared/models/enums/popup-modal-actions.enum';
import { IdName } from '@shared/models/id-name';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, tap } from 'rxjs/operators';
import { ProcedureOrderValues, SecondaryProcedureOrderForm } from './models/order-form';
import { OrderQuery } from './state/order-query';
import { OrderState, OrderStore } from './state/order-store';
import { Procedure } from '@shared/models/procedure';
import { ProcedureConfigurationService } from '@modules/order/services/procedure-configuration.service';
import { ProcedureMap } from '@shared/models/procedure-map';
import { ProcedureFormStateService } from '@modules/order/services/procedure-form-state.service';
import { ProcedureFormState } from '@modules/order/models/procedure-form-state';
import { OrderFormConfirmationService } from './services/order-form-confirmation.service';
import { SoftwareOptionsService } from '@shared/services/software-options.service';
import { ScanOptionsStore } from '@modules/scan-options/state/scan-options-store';
import { TreatmentStage } from '@shared/models/treatment-stage';
import { combineQueries } from '@datorama/akita';
import { SoftwareOptions } from '@shared/models/enums/enums';
import { ProcedureLoadRxService } from '@modules/order/services/procedure-load-rx.service';
import { DentureDetailsStore } from '@modules/denture-details/state/denture-details.store';
import { ToothEditorStore } from '@modules/tooth-editor/state/tooth-editor-store';
import { TreatmentStageEnum } from '@modules/order/models/treatment-stage.enum';
import { ProcedureEnum } from '@core/procedure-helpers/models/procedure.enum';
import { SendToIdForNotLabsEnum, SendToTypeEnum } from './models/send-to-type.enum';
import { OrderFacadeBase } from '@modules/order/order.facade.base';
import { ShellStore } from '@shared/store/shell/shell-store';
import { TypeEnum } from '@modules/order/models/type.enum';
import { RoleTypeEnum } from '@shared/models/role-type';
import { DefaultCrownStore } from '@modules/tooth-editor/state/default-crown-store';
import { RxForDoctorQuery } from '@modules/rx-for-doctor/state/rx-for-doctor-query';
import { AddRxService } from '@shared/services/add-rx.service';

@Injectable()
export class OrderV1Facade extends OrderFacadeBase {
	procedureMap$: Observable<ProcedureMap> = this.query.procedureMap$;
	procedureTreatmentStages$: Observable<TreatmentStage[]> = this.shellQuery.procedureTreatmentStages$;
	isReadOnly$: Observable<boolean> = this.shellQuery.getReadOnlyState('order');
	isReturned$: Observable<boolean> = this.shellQuery.isReturned$;

	get isProcedureAndTypeReadOnly$(): Observable<boolean> {
		return combineLatest([
			this.isReadOnly$,
			this.procedureMap$,
			this.shellQuery.isRxTakenForScan$,
			this.shellQuery.rx$,
			this.isReturned$,
			this.addRxService.isClonedRestorativeProcedure$
		]).pipe(
			map(([isReadOnly, procedureMap, isRxTakenForScan, rx, isReturned, isClonedRestorativeProcedure]) => {
				if (isClonedRestorativeProcedure) {
					return true;
				}

				if (isRxTakenForScan && !isReadOnly && !isReturned) {
					if (rx?.PrePrepScan) {
						return (procedureMap.ProcedureId as ProcedureEnum) === ProcedureEnum.FixedRestorative;
					}

					if (rx?.DentureDetails?.IsDentureCopyScan) {
						return (procedureMap.ProcedureId as ProcedureEnum) === ProcedureEnum.Denture_Removable;
					}
				}

				return isReadOnly || isReturned;
			})
		);
	}

	procedures$: Observable<Procedure[]> = this.procedureConfigurationService.procedures$;
	types$: Observable<IdName[]> = this.procedureConfigurationService.types$;
	iCastOrthoModelOnValue$: Observable<IdName> = this.types$?.pipe(map(types => (types.length > 0 ? types[0] : null)));

	isOrthoModelIcastEnabled$: Observable<boolean> = this.softwareOptionsService.hasScannerSoftwareOption$(SoftwareOptions.OrthoModelICast);
	isStudyModelIRecordEnabled$: Observable<boolean> = this.query.availableProcedureMaps$.pipe(
		map(procedureMaps =>
			procedureMaps.some(
				pm => (pm.ProcedureId as ProcedureEnum) === ProcedureEnum.StudyModel_iRecord && (pm.TypeId as TypeEnum) === TypeEnum.Empty
			)
		)
	);

	isCancellable$: Observable<boolean> = this.procedureMap$.pipe(
		map(currentProcedureMap =>
			this.query.availableProcedureMaps.some(
				pm =>
					(pm?.SendToTypes as SendToTypeEnum) === SendToTypeEnum.empty &&
					pm?.ProcedureId === currentProcedureMap?.ProcedureId &&
					pm?.TypeId === currentProcedureMap?.TypeId
			)
		)
	);

	procedureFormState$: Observable<ProcedureFormState> = combineQueries([
		this.procedureMap$,
		this.isReadOnly$,
		this.query.treatmentStage$,
		this.shellQuery.shouldValidateForSend$,
		this.shellQuery.proceduresMap$
	]).pipe(
		map(params => this.procedureFormStatusService.getFormState(...params)),
		shareReplay({ bufferSize: 1, refCount: true })
	);

	isSendToIDSMessageVisible$: Observable<boolean> = combineQueries([this.query.procedureMap$, this.query.availableProcedureMaps$, this.isReadOnly$]).pipe(
		map(
			([procedureMap, availableProcedureMaps, isReadOnly]) => 
				(!!procedureMap &&
					(procedureMap.ProcedureId as ProcedureEnum) === ProcedureEnum.StudyModel_iRecord &&
					(procedureMap.SendToTypes as SendToTypeEnum) !== SendToTypeEnum.chairsideExocad &&
					(procedureMap.SendToTypes as SendToTypeEnum) !== SendToTypeEnum.chairsideGlidewell &&
					availableProcedureMaps.some(pm => (pm.ProcedureId as ProcedureEnum) === ProcedureEnum.Invisalign)) ||
					isReadOnly
		)
	);

	userRole$: Observable<RoleTypeEnum> = this.shellQuery.userRole$;

	constructor(
		protected store: OrderStore,
		protected query: OrderQuery,
		protected shellStore: ShellStore,
		protected shellQuery: ShellQuery,
		protected logger: LoggerService,
		private orderFormConfirmationService: OrderFormConfirmationService,
		private teethDiagramStore: TeethDiagramStore,
		private scanOptionsStore: ScanOptionsStore,
		private procedureConfigurationService: ProcedureConfigurationService,
		private procedureFormStatusService: ProcedureFormStateService,
		private softwareOptionsService: SoftwareOptionsService,
		private procedureLoadRxService: ProcedureLoadRxService,
		private dentureDetailsStore: DentureDetailsStore,
		private toothEditorStore: ToothEditorStore,
		private defaultCrownStore: DefaultCrownStore,
		private rxForDoctorQuery: RxForDoctorQuery,
		private addRxService: AddRxService
	) {
		super(store, query, shellQuery, logger);
	}

	handleProcedureChanged(procedureId: number): Observable<{ returnToProcedure?: IdName; procedureOrderValues?: ProcedureOrderValues }> {
		const currentProcedure = this.query.procedure;
		const currentProcedureId = currentProcedure?.Id;

		if (currentProcedureId === procedureId) {
			return of();
		}

		if (currentProcedureId) {
			return this.orderFormConfirmationService.openPopUp$(true).pipe(
				map(dialogResult => {
					if (dialogResult === PopUpActions.Ok) {
						const procedureOrderValues = this.resetStateWithProcedure(procedureId);

						this.resetStoresDependingOnProcedure();

						return { procedureOrderValues };
					}

					return { returnToProcedure: this.query.procedure };
				})
			);
		}

		return of({ procedureOrderValues: this.resetStateWithProcedure(procedureId) });
	}

	handleTypeChanged(typeId: number): { shouldResetSendTo: boolean } {
		const currentProcedureMap = this.query.procedureMap;
		const currentTypeId = currentProcedureMap?.TypeId;
		const currentSendToId = this.query.sendTo?.Id;

		if (!currentProcedureMap || typeId === currentTypeId) {
			return { shouldResetSendTo: false };
		}

		if (typeId !== currentTypeId) {
			this.dentureDetailsStore.update({ isUpperJawChecked: false, isLowerJawChecked: false });
		}

		let procedureMap = this.procedureConfigurationService.getProcedureMap(currentProcedureMap.ProcedureId, typeId, currentSendToId);

		if (!procedureMap) {
			procedureMap = this.procedureConfigurationService.getProcedureMap(currentProcedureMap.ProcedureId, typeId);
		}

		const availableLabs = this.procedureConfigurationService.getAvailableLabs({ procedureId: currentProcedureMap.ProcedureId, typeId });

		if ([ProcedureEnum.ImplantPlanning, ProcedureEnum.Denture_Removable].includes(procedureMap.ProcedureId)) {
			this.teethDiagramStore.resetTeeth();
		}

		if (availableLabs?.some(x => x.Id === currentSendToId)) {
			this.store.update({ procedureMap, availableLabs });

			return { shouldResetSendTo: false };
		} else {
			this.store.update({ procedureMap, availableLabs, sendTo: null });

			return { shouldResetSendTo: true };
		}
	}

	handleSendToChanged(sendTo: IdName): Observable<IdName> {
		if (sendTo?.Id === this.query.sendTo?.Id) {
			return of(this.query.sendTo);
		}

		if (sendTo?.Id === SendToIdForNotLabsEnum.glidewell) {
			return this.orderFormConfirmationService.openPopUp$(true).pipe(
				map(dialogResult => {
					if (dialogResult === PopUpActions.Ok) {
						this.teethDiagramStore.resetTeeth();
						this.updateSendToStorage(sendTo);

						return sendTo;
					} else {
						return this.query.sendTo;
					}
				})
			);
		}

		this.updateSendToStorage(sendTo);

		return of(sendTo);
	}

	handleTreatmentStageChanges(treatmentStage: IdName): { shouldUpdateCurrentAligner: boolean; currentAligner?: string } {
		const currentTreatmentStage = this.query.treatmentStage;

		if (treatmentStage?.Id === currentTreatmentStage?.Id) {
			return { shouldUpdateCurrentAligner: false };
		}

		let newState: Partial<OrderState> = {
			treatmentStage
		};

		let shouldUpdateCurrentAligner = true;

		if (
			!treatmentStage ||
			(currentTreatmentStage?.Id === TreatmentStageEnum.InitialRecord && treatmentStage?.Id !== TreatmentStageEnum.InitialRecord)
		) {
			newState = { ...newState, currentAlignerId: null };
		} else if (
			currentTreatmentStage?.Id !== TreatmentStageEnum.InitialRecord &&
			treatmentStage?.Id === TreatmentStageEnum.InitialRecord
		) {
			newState = { ...newState, currentAlignerId: '0' };
		} else {
			shouldUpdateCurrentAligner = false;
		}

		this.store.update(newState);

		return { shouldUpdateCurrentAligner, currentAligner: newState.currentAlignerId };
	}

	handleProcedureFlowValuesChanges(formValue: SecondaryProcedureOrderForm): void {
		const { dueDate, currentAlignerId } = formValue;

		this.store.update({ dueDate, currentAlignerId });
	}

	loadProcedureFlowOrder(): Observable<ProcedureOrderValues> {
		return this.procedureLoadRxService.loadProcedureFlowOrder();
	}

	getDefaultProcedure(): Observable<Procedure> {
		return combineLatest([
			this.shellQuery.defaultProcedure$,
			this.procedures$,
			this.shellQuery.isNewAndNotClonedRx$,
			this.rxForDoctorQuery.isReferralWorkflowPractice$
		]).pipe(
			filter(([, , isNewRx]) => isNewRx),
			map(([procedureId, procedures, , isReferralWorkflowPractice]) => {
				return isReferralWorkflowPractice
					? procedures.find(x => x.Id === ProcedureEnum.Invisalign)
					: procedures.find(x => x.Id === procedureId);
			}),
			distinctUntilChanged()
		);
	}

	setDirectToLab(): Observable<boolean> {
		return combineQueries([this.shellQuery.directToLabs$, this.query.procedureMap$, this.query.sendTo$]).pipe(
			filter(([directToLabs, procedureMap]) => !!directToLabs && !!procedureMap),
			map(([directToLabs, procedureMap, sendTo]) => this.calcDirectToLab(procedureMap.CaseTypeId, directToLabs, sendTo)),
			filter(directToLab => directToLab !== this.query.directToLab),
			tap(directToLab => {
				this.store.update({ directToLab });
			})
		);
	}

	private static getProcedureBasedInitState(): Partial<OrderState> {
		return {
			treatmentStage: null,
			dueDate: null,
			currentAlignerId: null,
			sendTo: null,
			isMultiBiteSelected: null
		};
	}

	private resetStateWithProcedure(procedureId: number): ProcedureOrderValues {
		const state = OrderV1Facade.getProcedureBasedInitState();
		const procedureMap = this.procedureConfigurationService.getProcedureMap(procedureId);
		const availableLabs = this.procedureConfigurationService.getAvailableLabs({ procedureId });

		this.store.update({ ...state, procedureMap, availableLabs });
		if (!this.shellQuery.rxConfiguration?.Types) {
			this.logger.error(`Rx configuration is missing types ${JSON.stringify(this.shellQuery.rxConfiguration)}`, {
				module: 'procedure-configuration.service'
			});
		}

		const type = this.shellQuery.rxConfiguration?.Types?.find(x => x.Id === procedureMap?.TypeId);
		const sendTo =
			state.sendTo?.Id ?? SendToTypeEnum.empty === (procedureMap?.SendToTypes as SendToTypeEnum) ? state.sendTo : availableLabs?.[0];

		return {
			dueDate: state.dueDate,
			currentAlignerId: state.currentAlignerId,
			treatmentStage: state.treatmentStage,
			type,
			sendTo
		};
	}

	private resetStoresDependingOnProcedure(): void {
		this.scanOptionsStore.reset();
		this.dentureDetailsStore.reset();
		this.teethDiagramStore.resetTeeth();
		this.toothEditorStore.reset();
		this.defaultCrownStore.reset();
		this.shellStore.updateProcedureChangedFlag(true);
		this.store.updateIsDraft(false);
	}

	private updateSendToStorage(sendTo: IdName) {
		const { ProcedureId, TypeId } = this.query.procedureMap;
		const procedureMap = this.procedureConfigurationService.getProcedureMap(ProcedureId, TypeId, sendTo?.Id);

		if (procedureMap.Id !== this.query.procedureMap?.Id) {
			this.store.update({ procedureMap, sendTo });
		} else {
			this.store.update({ sendTo });
		}
	}
}
